import React from 'react';
import styled from 'styled-components';
import FooterCol from './FooterCol';
import PText from './PText';

const FooterStyle = styled.div`
  background-color: var(--deep-dark);
  padding-top: 10rem;
  .container {
    display: flex;
    gap: 3rem;
  }
  .footer__col1 {
    flex: 2;
  }
  .footer__col2,
  .footer__col3,
  .footer__col4 {
    flex: 1;
  }
  .footer__col1__title {
    font-size: 3.5rem;
    margin-bottom: 1rem;
  }
  .copyright {
    background-color: var(--dark-bg);
    text-align: left;
    padding: 1rem 0;
    margin-top: 5rem;
    .para {
      margin-left: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    .container {
      flex-direction: column;
      gap: 0rem;
      & > div {
        margin-top: 5rem;
      }
    }
    .footer__col1 .para {
      max-width: 100%;
    }
    .copyright {
      .container {
        div {
          margin-top: 0;
        }
      }
    }
  }
`;

export default function Footer() {
  return (
    <FooterStyle>
      <div className="container">
        <div className="footer__col1">
          <h1 className="footer__col1__title">Artistas Rivera</h1>
          <PText>
            Somos <kc>Artistas Rivera</kc>, un grupo experto en diseños personalizados y 
            temáticos especializados en la imitación natural para la conveniencia de hogares,
            empresas y parques de la
            más <kc>alta calidad</kc>.
          </PText>
        </div>
        <div className="footer__col2">
          <FooterCol
            heading="Nav Rápido"
            links={[
              {
                title: 'Home',
                path: '/',
                type: 'Link',
              },
              {
                type: 'Link',
                title: 'Quiénes Somos',
                path: '/about',
              },
              {
                type: 'Link',
                title: 'Projectos',
                path: '/projects',
              },
              {
                type: 'Link',
                title: 'Contacto',
                path: '/contact',
              },
            ]}
          />
        </div>
        <div className="footer__col3">
          <FooterCol
            heading="Contacto Rápido"
            links={[
              {
                title: '+11 5462 9358',
                path: 'tel:+1154629358',
              },
              {
                title: 'artistasrivera@gmail.com',
                path: 'mailto:artistasrivera@gmail.com',
              },
              {
                title: 'Buenos Aires, Argentina',
                path: 'http://google.com/maps',
              },
            ]}
          />
        </div>
        <div className="footer__col4">
          <FooterCol
            heading="social Links"
            links={[
              {
                title: 'Facebook',
                path: 'https://www.facebook.com/profile.php?id=100090922249217',
              },
              {
                title: 'Instagram',
                path: 'http://instagram.com/artistas_rivera',
              },
            ]}
          />
        </div>
      </div>
      <div className="copyright">
        <div className="container">
          <PText>
            © 2023 - <kc>Artistas Rivera </kc> | Diseñado por Tai 
          </PText>
        </div>
      </div>
    </FooterStyle>
  );
}
