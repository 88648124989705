import { v4 as uuidv4 } from 'uuid';
import FogonMartinez from '../images/IMG_1454.JPG';
import CascadaMartinez from '../images/IMG_1448.JPG';
import GabPilar from '../images/IMG_0130.jpeg';
import CascNordelta from '../images/IMG_0633.jpeg';
import ArchFosil from '../images/archaopteryx-termi.jpg';
import ObraCardales from '../images/IMG_0755.jpeg';
import MuralPaloma from '../images/IMG_0776.jpeg';
 
const projects = [
  {
    id: uuidv4(),
    id2: 1,
    name: 'Piscina con Rocas y Cascada Martinez',
    desc:
      'Piscina tipo playa con terminaciones simil piedra en Martinez. Con fotos antes y después.',
    img: CascadaMartinez,
  },
  {
    id: uuidv4(),
    id2: 2,
    name: 'Gabinete Pilar',
    desc:
      'Recubrimiento de gabinete con terminaciones simil piedra en Pilar.',
    img: GabPilar,
  },
  {
    id: uuidv4(),
    id2: 3,
    name: 'Cascada Nordelta',
    desc:
      'Pequeña cascada con otras terminaciones simil piedra.',
    img: CascNordelta,
  },
  {
    id: uuidv4(),
    id2: 4,
    name: 'Archaeopteryx en Yeso',
    desc:
      'Imitación de uno de los fósiles más interesantes del museo de Berlin.',
    img: ArchFosil,
  },
  {
    id: uuidv4(),
    id2: 5,
    name: 'Fogón simple Martinez',
    desc:
      'Fogón simple simil piedra.',
    img: FogonMartinez,
  },
  {
  id: uuidv4(),
    id2: 6,
    name: 'Obra en Cardales',
    desc:
      'Piso, recubrimiento, cascada y barra simil piedra para una pisicna tipo playa.',
    img: ObraCardales,
  },
  {
  id: uuidv4(),
    id2: 7,
    name: 'Mural Callejero',
    desc:
      'Mural realizado por Dani Rivera.',
    img: MuralPaloma,
  },
];


export default projects;