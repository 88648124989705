import React, { useRef } from 'react';
import SwiperCore, { Navigation, Pagination, Autoplay, Lazy, Keyboard } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';

SwiperCore.use([Navigation, Pagination, Autoplay, Lazy, Keyboard]);

const GalleryStyle = styled.div`
  padding: 0rem 0;
  .galleryitems
	.myswiper {
	width:100%;
	height:100vh;
	.swiperslide {
		.swiper-pagination {
		  position: absolute;
		  text-align: center;
		  transition: 300ms opacity;
		  transform: translate3d(0, 0, 0);
		  z-index: 10;
		  top: 0 !important;
	  img {
		  width:100%;
		  height: auto;
		  object-fit: cover;
		  display: block;
	  }
	.swiperNavNext,
	.swiperNavPrev {
	top:50%;
	postion: absolute;
	transform: translateY(-60%);
    width:50px;
	height:50px;
	zindex: 1;
	cursor: pointer;
	background-repeat: no-repeat;
	background-size: contain;
	background-position: center;
	      }
	  .swiperNavPrev {
		  left:0;
	  }
	  .swiperNavNext {
			right:0;
		  }
		  }
	    } 
      }
	}
`;

export default function Gallery({ photos = [] }) {
	
	const swiperNavPrevRef = useRef();
	const swiperNavNextRef = useRef();
	const swiperPagination = useRef();
		
	return (
	 <GalleryStyle>
	 <div className="galleryitems">
	   <Swiper
		 modules={[Navigation, Pagination, Autoplay, Lazy, Keyboard]}
		 navigation={{
			 prevEl: swiperNavPrevRef.current,
			 nextEl: swiperNavNextRef.current,
		 }}
		 pagination={{
		el: swiperPagination.current,
		dynamicBullets: true,
		dynamicMainBullets: 11,
		clickable: true,
		 }}
		 style={{
	   "--swiper-theme-color":"#DC143C",
	   "--swiper-pagination-color": "#DC143C",
       "--swiper-pagination-bullet-inactive-color": "#212121",
	   "--swiper-pagination-bullet-inactive-opacity": "1",
       "--swiper-pagination-bullet-size": "9px",
	   "--swiper-pagination-bullet-horizontal-gap": "7px" }}
		 speed={800}
		 slidesPerView={1}
		 loop={true}
         LazyLoading={true}
		 keyboard={{enabled: true,}}
		 
		 
		 className="myswiper">
		 
		  <div className="swiperNavPrev" ref={swiperNavPrevRef}/>
			 <div className="swiperNavNext" ref={swiperNavNextRef}/>
			   <div className="swiper-pagination" ref={swiperPagination}/>
			 
		  {photos.map((photo) =>
			 <SwiperSlide key={photo} className="swiperslide">
			   <img
				 src={require(`../assets/images/${photo.toString()}`)} 
				 alt=""
			   />
			 </SwiperSlide>
		   )}
	   </Swiper>
	 </div>
	 </GalleryStyle>
	);
}