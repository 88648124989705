import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Footer from './components/Footer';
import NavMenu from './components/NavMenu';
import ScrollToTop from './components/ScrollToTop';
import  About  from './pages/About';
import  Contact  from './pages/Contact';
import  Home  from './pages/Home';
import  Projects  from './pages/Projects';
import  Gallery  from './pages/Gallery';


export default function App()  {
    

  return (
    <>
      <Router>
        <NavMenu />
        <ScrollToTop />
        <Routes>
         <Route path="/gallery/:title/1" //Muro Cascada Martinez
        element={<Gallery photos={['before-work.jpg', 'before-work-2.jpg',
       'IMG_1426.JPG', 'IMG_1482.JPG', 'IMG_1452.JPG', 'IMG_1481.JPG', 'IMG_1445.JPG', 'IMG_1425.JPG', 'IMG_1431.JPG'
       , 'IMG_1462.JPG', 'IMG_1463.JPG', 'IMG_1457.JPG' , 'IMG_1477.JPG' , 'IMG_1473.JPG' , 'IMG_1448.JPG'
       , 'IMG_1442.JPG' , 'IMG_1451.JPG' , 'IMG_1465.JPG', 'IMG_1434.JPG' , 'IMG_1433.JPG' , 'IMG_1461.JPG'
       , 'IMG_1450.JPG' , 'IMG_1439.JPG' , 'IMG_1428.JPG' , 'IMG_1432.JPG' , 'IMG_1440.JPG' , 'IMG_1424.JPG'
       , 'IMG_1455.JPG' , 'IMG_1472.JPG', 'IMG_1466.JPG' , 'IMG_1470.JPG'
      ]} />}
          />
          <Route path="/gallery/:title/2" //Gabinete Pilar
          element={<Gallery photos={['IMG_0042.jpeg', 'IMG_0044.jpeg', 'IMG_0046.jpeg', 'IMG_0054.jpeg',  'IMG_0056.jpeg','IMG_0047.jpeg', 'IMG_0053.jpeg', 
          'IMG_0055.jpeg', 'IMG_0057.jpeg', 'IMG_0077.jpeg', 'IMG_0078.jpeg', 'IMG_0125.jpeg', 'IMG_0126.jpeg',
          'IMG_0127.jpeg', 'IMG_0124.jpeg', 'IMG_0130.jpeg'
        ]} />}
            />
            <Route path="/gallery/:title/3" //Cascada Nordelta
            element={<Gallery photos={['IMG_0667.jpeg', 'IMG_0664.jpeg', 'IMG_0660.jpeg', 'IMG_0637.jpeg', 'IMG_0638.jpeg', 'IMG_0634.jpeg',
          'IMG_0635.jpeg', 'IMG_0655.jpeg' ]} />}
              />
              <Route path="/gallery/:title/4" // Archaeopteryx
              element={<Gallery photos={['IMG_0337.jpeg', 'IMG_0342.jpeg',
                'IMG_0421.jpeg', 'IMG_0428.jpeg', 'IMG_0434.jpeg',
                'IMG_0439.jpeg', 'IMG_0548.jpeg', 'IMG_0570.jpeg',
                'IMG_0923.jpeg', 'IMG_0950.jpeg', 'IMG_0954.jpeg',
                'IMG_0998.jpeg', 'IMG_0999.jpeg',
              'archi1.jpg', 'archi2.jpg', 'archi3.jpg']} />}
                />
                <Route path="/gallery/:title/5" // Fogon Martinez
                element={<Gallery photos={['IMG_1429.JPG', 'IMG_1454.JPG', 'IMG_1439.JPG']} />}
                  />
                  <Route path="/gallery/:title/6" // Cardales Piscina
                  element={<Gallery photos={['IMG_0750.jpeg', 'IMG_0717.jpeg', 'IMG_0526.jpeg', 'IMG_0466.jpeg', 'IMG_0614.jpeg', 'IMG_0745.jpeg', 
                'IMG_0703.jpeg', 'IMG_0754.jpeg', 'IMG_0717.jpeg','IMG_0741.jpeg', 'IMG_0722.jpeg', 'IMG_0733.jpeg', 'IMG_0624.jpeg', 'IMG_0478.jpeg', 'IMG_0558.jpeg', 'IMG_0553.jpeg', 'IMG_0728.jpeg']} />}
                    />
                    <Route path="/gallery/:title/7" // Mural La Paloma
                      element={<Gallery photos={['IMG_0776.jpeg']} />}
                        />
      <Route path="/about" element={<About />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/projects" element={<Projects />} />
      <Route path="/" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}
