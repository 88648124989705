import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import styled from "styled-components";
import ReCAPTCHA from "react-google-recaptcha";

const ContactForm = () => {
  
  const form = useRef();
  const captchaRef = useRef(null);

  const sendEmail = (e) => {
    e.preventDefault();
    
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        form.current,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("Durarara");
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <StyledContactForm>
      <form ref={form} onSubmit={sendEmail}>
        <label>Nombre</label>
        <input type="text" name="user_name" id="nameID" placeholder='Introduce tu nombre ..' 
        minLength={5} maxLength={20} required/>
        <label>Email</label>
        <input type="email" name="user_email" id="emailID" placeholder='Tu email ..' 
        minLength={12} maxLength={40} required/>
        <label>Mensaje</label>
        <textarea name="message" id="messageID" placeholder='Tu mensaje ..' minLength={15} maxLength={666} required/>
        <yamcha>
        <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY}
        theme="dark"
        ref={captchaRef}
        />
        </yamcha>
        <input type="submit" value="Enviar"/>
      </form>
      </StyledContactForm>
  );
};

export default ContactForm;

const StyledContactForm = styled.div`
  width: 100%;
  #nameID::placeholder {
      color: var(--grey);
      opacity: 35%;
  }
  #emailID::placeholder {
      color: var(--grey);
      opacity: 35%;
  }
  #messageID::placeholder {
      color: var(--grey);
      opacity: 35%;
  }
  form {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    font-size: 16px;
    }
    input,
    textarea {
    width: 100%;
    font-size: 2rem;
    padding: 1.2rem;
    color: var(--gray-1);
    background-color: var(--deep-dark);
    outline: none;
    border: none;
    border-radius: 8px;
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
      }
    }
    textarea {
    min-height: 250px;
    resize: vertical;
      }
    }
    yamcha {
    padding: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    }
    label {
      margin-top: 1rem;
      font-size: 1.8rem;
    }
    input[type="submit"] {
    background-color: var(--gray-1);
    color: var(--black);
    font-size: 2rem;
    display: inline-block;
    outline: none;
    border: none;
    padding: 1rem 4rem;
    border-radius: 8px;
    cursor: pointer;
    }
  }
`;